<template>
  <div>
    <van-nav-bar title="标题" left-text="返回" left-arrow @click-left="onClickLeft">
      <template #right>
        <div @click="onShowPopup" style="color: #1989fa">选好了</div>
      </template>
    </van-nav-bar>
    <van-checkbox-group v-model="result">
      <van-cell-group inset>
        <van-cell
            v-for="(item, index) in list"
            clickable
            :key="index"
            @click="toggle(index)"
        >
          <div class="van-address-item">
            <div class="van-cell van-cell--borderless">
              <div class="van-cell__value van-cell__value--alone van-address-item__value">
                <div role="radio" class="van-radio" tabindex="0" aria-checked="true">
                  <div class="van-radio__icon van-radio__icon--round van-radio__icon--checked"
                       style="font-size: 18px; height: auto;">
                    <van-tag v-if="item.type == 0" style="display: flex" type="success" size="medium">单一账单</van-tag>
                    <van-tag v-if="item.type == 1" style="display: flex; margin-top: 3px;" color="#ffe1e1"
                             text-color="#ad0000" size="medium">合并账单
                    </van-tag>
                  </div>
                  <span class="van-radio__label">
                  <div class="van-address-item__name">
                    {{ item.realName }}
              </div>
              <div class="van-address-item__name">
                总计：{{ item.totalPrice }}元 欠款：{{ item.debtPrice }}元
              </div>
              <div class="van-address-item__address">{{ item.createTime }}</div>
            </span></div>
              </div>
            </div>
          </div>
          <template #right-icon>
            <van-checkbox
                :name="item"
                ref="checkboxes"
                slot="right-icon"
            />
          </template>
        </van-cell>
      </van-cell-group>
    </van-checkbox-group>

    <van-popup v-model="isPopupShow" position="bottom" :style="{ height: '40%' }">
      <van-form @submit="onSubmit">
        <van-cell-group inset>
          <van-field v-model="username" label="文本" placeholder="请输入用户名"/>
          <van-notice-bar color="#1989fa" background="#ecf9ff" left-icon="info-o">
            合并欠账单金额为：{{ total }} 元
          </van-notice-bar>
          <van-field v-model="totalPrice"
                     size="small" label="支付金额" rows="1" type="number" style="margin-bottom: 3px;"/>
          <van-field
              v-model="mark"
              rows="2"
              autosize
              label="留言"
              type="textarea"
              maxlength="50"
              placeholder="请输入留言"
              show-word-limit
          />
        </van-cell-group>
        <div style="margin: 16px;">
          <van-button round block type="primary" native-type="submit">
            提交
          </van-button>
        </div>
      </van-form>
    </van-popup>
  </div>

</template>

<script>
import {
  Row,
  Col,
  Icon,
  Cell,
  CellGroup,
  Toast,
  Form,
  Button,
  Field,
  Uploader,
  Popup,
  ContactCard,
  Tag,
  NoticeBar,
  Card,
  Stepper,
  SubmitBar,
  Divider,
  Checkbox,
  DatetimePicker,
  Sticky,
  Tab,
  Tabs,
  Sidebar,
  SidebarItem, Loading, PullRefresh, ImagePreview, List, CheckboxGroup, Image, Notify, Empty, Dialog
  , CouponCell,
  AddressList,
  NavBar
} from 'vant';

import * as adminOrderService from "@/api/admin/order";
import {ref, onBeforeUpdate} from 'vue';


export default {
  components: {
    [Row.name]: Row,
    [Col.name]: Col,
    [Icon.name]: Icon,
    [Cell.name]: Cell,
    [CellGroup.name]: CellGroup,
    [Toast.name]: Toast,
    [Form.name]: Form,
    [Button.name]: Button,
    [Field.name]: Field,
    [Uploader.name]: Uploader,
    [Popup.name]: Popup,
    [ContactCard.name]: ContactCard,
    [Tag.name]: Tag,
    [Card.name]: Card,
    [Stepper.name]: Stepper,
    [SubmitBar.name]: SubmitBar,
    [Divider.name]: Divider,
    [Checkbox.name]: Checkbox,
    [DatetimePicker.name]: DatetimePicker,
    [Sticky.name]: Sticky,
    [Tab.name]: Tab,
    [Tabs.name]: Tabs,
    [Sidebar.name]: Sidebar,
    [SidebarItem.name]: SidebarItem,
    [Loading.name]: Loading,
    [PullRefresh.name]: PullRefresh,
    [ImagePreview.Component.name]: ImagePreview.Component,
    [List.name]: List,
    [CheckboxGroup.name]: CheckboxGroup,
    [NoticeBar.name]: NoticeBar,
    [Notify.name]: Notify,
    [CouponCell.name]: CouponCell,
    [AddressList.name]: AddressList,
    [NavBar.name]: NavBar

  },
  name: "OrderList",
  props: ["searchParam"],
  data() {
    return {
      isPopupShow: false,
      totalPrice: 100,
      paymentPrice: 20,
      payPrice: 0,
      total: 0,
      mark: null,
      username: null,
      value: null,
      dataList: [],
      loading: false,
      finished: false,
      refreshing: false,
      pages: {
        perPage: 8,
        currPage: 1,
        pageCount: 3
      },

      orderInfos: [],
      result: [],
      list: ['a', 'b'],
      // checked1: 1,
      ids: []
    };
  },
  created() {
    console.log(this.$route.params)
    if (this.$route.params && this.$route.params.userId) {
      this.initData()
    }
  },
  methods: {
    onClickLeft() {
      this.$router.go(-1);
    },
    initData() {
      adminOrderService.listDebtMerge(this.$route.params.userId).then(res => {
        this.list = res
        if (this.$route.params && this.$route.params.checkedDebtId) {
          res.forEach((item) => {
            if (item.id == this.$route.params.checkedDebtId) {
              this.result.push(item)
            }
          })
        }
      });
    },
    onCheckConfirm() {
    },
    onShowPopup() {
      const myDate = new Date();
      let name
      this.result.forEach(i => {
        name = i.realName
        this.ids.push(i.id)
        this.total += Number(i.debtPrice)
      })
      if (name.indexOf('【合并单】') > 0) {
        name = name.split("【合并单】")[0]
        name += '【合并单】' + myDate.toLocaleString()
      } else {
        name = name + '【合并单】' + myDate.toLocaleString()
      }
      this.username = name
      this.totalPrice = this.total
      this.isPopupShow = true
    },
    onSubmit() {
      if (this.result.length <= 1) {
        Toast("账单不能少于两个")
        return
      }
      let paramObj = {
        username: this.username,
        totalPrice: this.totalPrice,
        mark: this.mark,
      }
      adminOrderService.mergeDebt(this.ids.join(','), paramObj).then(res => {
        Notify({type: 'success', message: '删除成功'});
        this.isPopupShow = false
        this.initData()
      });
    },
    checkAll() {
      this.$refs.checkboxGroup.toggleAll(true);
    },
    toggleAll() {
      this.$refs.checkboxGroup.toggleAll();
    },
    toggle(index) {
      console.log(index)
      this.$refs.checkboxes[index].toggle();
    },
    toOrderInfo(orderId) {
      this.$router.push({path: '/order/info/' + orderId})
    },
    deleteOrder(orderId) {
      Dialog.confirm({
        title: '确认删除此订单吗？',
        message: '删除此订，无法找回！',
      })
          .then(() => {
            adminOrderService.deleteAccountOrder(orderId).then(res => {
              if (res.status === 200) {
                Notify({type: 'success', message: '删除成功'});
                this.onRefresh()
              } else {
                Notify({type: 'danger', message: '删除失败'});
              }
            })
            // on confirm
          })
          .catch(() => {
            // on cancel
          });

    },
    toOrderIndo() {
    },
    getAccountOrderInfo(orderId) {
      adminOrderService.getAccountOrderInfo(orderId).then(res => {
        if (res.status === 200) {
          this.orderInfos = res.data
        }
      });
      this.orderInfoPopupShow = true;
    },
    onLoad() {
      console.log('onload')
      if (this.refreshing) {
        this.dataList = [];
        this.refreshing = false;
      }
      // let param = 'page=' + this.pages.currPage + '&limit=10';
      this.searchParam.page = this.pages.currPage
      this.searchParam.limit = 10
      // if (this.orderInfo.userId !== undefined) {
      //   param += '&accountUserId=' + this.orderInfo.userId;
      // }
      let that = this;
      adminOrderService.getAccountList(this.searchParam).then(res => {
        if (res.status === 200) {
          that.dataList = that.dataList.concat(res.data.records)
          this.loading = false;
          that.pages.currPage++;
          that.pages.pageCount = res.data.pages;
          console.log(this.pages)
          if (that.pages.currPage > that.pages.pageCount) {
            that.finished = true;
          }

          console.log(that.dataList)
        }
      });
    },
    onRefresh() {
      console.log('父亲刷新我')
      console.log(this.searchParam)
      // 清空列表数据
      this.finished = false;
      this.dataList = [];
      // 重新加载数据
      // 将 loading 设置为 true，表示处于加载状态
      this.loading = true;
      this.pages.currPage = 1;
      console.log(this.pages)
      this.onLoad();
    },
    resetData() {
      this.pages = {
        perPage: 8,
        currPage: 1,
        pageCount: 3
      };
      this.loading = false;
      this.finished = false;
      this.refreshing = false;
    },
  },
};
</script>

<style lang="less">
.van-contact-card1 {
  position: relative;
  display: flex;
  box-sizing: border-box;
  width: 100%;
  padding: 10px 16px;
  overflow: hidden;
  color: #323233;
  font-size: 14px;
  line-height: 24px;
  background-color: #fff;
}

.van-contact-card1::before {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  height: 2px;
  background: -webkit-repeating-linear-gradient(135deg, #ff6c6c 0, #ff6c6c 20%, transparent 0, transparent 25%, #1989fa 0, #1989fa 45%, transparent 0, transparent 50%);
  background: repeating-linear-gradient(-45deg, #ff6c6c 0, #ff6c6c 20%, transparent 0, transparent 25%, #1989fa 0, #1989fa 45%, transparent 0, transparent 50%);
  background-size: 80px;
  content: '';
}

.van-contact-card2::before {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  height: 2px;
  background: -webkit-repeating-linear-gradient(135deg, #ff6c6c 0, #ff6c6c 20%, transparent 0, transparent 25%, #1989fa 0, #1989fa 45%, transparent 0, transparent 50%);
  background: repeating-linear-gradient(-45deg, #ff6c6c 0, #ff6c6c 20%, transparent 0, transparent 25%, #1989fa 0, #1989fa 45%, transparent 0, transparent 50%);
  background-size: 80px;
  content: '';
}

.custom-title {
  margin-right: 4px;
  vertical-align: middle;
  display: flex;
  margin-left: 3px;
}

.search-icon {
  font-size: 16px;
  line-height: inherit;
}

.user {
  &-poster {
    width: 100%;
    height: 53vw;
    display: block;
  }

  &-group {
    margin-bottom: 15px;
  }

  &-links {
    padding: 15px 0;
    font-size: 12px;
    text-align: center;
    background-color: #fff;

    .van-icon {
      display: block;
      font-size: 24px;
    }
  }
}


.address-popup-load {
  text-align: center;
  margin: 0 auto;
  padding: 0;
}

.card-goods {
  padding: 10px 0;
  background-color: #fff;

  &__item {
    position: relative;
    background-color: #fafafa;

    .van-checkbox__label {
      width: 100%;
      height: auto; // temp
      padding: 0 10px 0 15px;
      box-sizing: border-box;
    }

    .van-checkbox__icon {
      top: 50%;
      left: 10px;
      z-index: 1;
      position: absolute;
      margin-top: -10px;
    }

    .van-card__price {
      color: #f44;
    }
  }
}

.va-tag-su {
  margin-left: 2px
}

.order-tag-margin-left {
  margin-left: 2px
}
</style>
